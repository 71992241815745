import React from "react";
import Layout from "../../components/Layout";
import { DonatePageTemplate } from "../../templates/donate-page";

const DonatePageThanks = () => {
  return (
    <Layout>
      <DonatePageTemplate submitted />
    </Layout>
  );
};

export default DonatePageThanks;
